import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '../../utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary-4 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary-4 text-neutral-0 shadow hover:bg-primary-4/90',
        danger: 'bg-danger-4 text-neutral-0 shadow-sm hover:bg-danger-4/90',
        outline: 'border border-neutral-2 bg-neutral-0 shadow-sm hover:bg-neutral-1 hover:text-neutral-7',
        secondary: 'bg-neutral-1 text-neutral-7 shadow-sm hover:bg-neutral-1/80',
        ghost: 'hover:bg-neutral-1 hover:text-neutral-7',
        link: 'text-primary-4 underline-offset-4 hover:underline',
        combobox:
          '!px-3 border border-neutral-2 bg-neutral-0 shadow-sm hover:bg-neutral-1 hover:text-neutral-7 font-normal',
      },
      size: {
        default: 'h-9 px-4 py-2',
        sm: 'h-8 rounded-md px-3 text-xs',
        lg: 'h-10 rounded-md px-8',
        icon: 'h-9 w-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  loading?: boolean;
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, loading, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }), loading && 'cursor-wait pointer-events-none')}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
